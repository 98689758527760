<template>
  <div>
    <router-link
      :to="{ name: 'root' }"
      class="dropdown-item is-size-6"
      :class="{ [classActive]: is('root') }"
      v-if="isUser"
      @click.native="track('go_to_dahsboard')"
    >
      Dashboard
    </router-link>
    <hr class="dropdown-divider" v-if="isUser" />
    <router-link
      @click.native="track('go_to_multi')"
      class="dropdown-item is-size-6"
      :class="{ [classActive]: is('multi-dash') }"
      :to="{ name: 'multi-dash' }"
      v-if="hasMulti"
    >
      Multi
    </router-link>
    <router-link
      @click.native="track('go_to_multi')"
      class="dropdown-item is-size-6"
      :class="{ [classActive]: is('explorer') }"
      :to="{ name: 'explorer' }"
      v-if="hasValuationExplorer"
      >Valuation Explorer</router-link
    >
    <router-link
      @click.native="track('go_to_stockview')"
      class="dropdown-item is-size-6"
      :class="{ [classActive]: is('stockview') }"
      :to="{ name: 'stockview' }"
      v-if="hasStockview"
    >
      Stockview
    </router-link>
    <router-link
      class="dropdown-item is-size-6"
      :to="{ name: 'auth-login' }"
      :class="{ [classActive]: is('auth-login') }"
      v-else-if="isGuest && $route.name !== 'auth-login'"
    >
      Log in
    </router-link>
    <hr
      class="dropdown-divider"
      v-if="hasMulti || hasStockview || hasValuationExplorer"
    />
    <a
      @click="openAutoCheckLink"
      class="dropdown-item is-size-6"
      v-if="$experian"
    >
      How to use AutoCheck
    </a>
    <AppHeaderBrandContact />
    <a
      @click.prevent="openContactForm"
      class="dropdown-item is-size-6"
      v-if="$experian && $route.name === 'autocheck'"
    >
      Get support
    </a>
    <hr class="dropdown-divider" />
    <div class="dropdown-item">
      <p class="is-size-7 is-family-monospace has-text-grey-light">
        version#{{ $version }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderBrandNav',
  components: {
    AppHeaderBrandContact: () => import('./AppHeaderBrandContact')
  },
  computed: {
    classActive: () => 'is-active',
    ...mapGetters('auth', [
      'isUser',
      'isGuest',
      'hasMulti',
      'hasValuationExplorer',
      'hasStockview'
    ])
  },
  methods: {
    is(name) {
      return this.$route.name === name
    },
    track(action) {
      this.$mxp.track(`app_header_${action}`)
    },
    openContactForm() {
      this.track('open_contact_form_general')
      this.$modal.open('contact/Contact', { product: 'General' })
    },
    openAutoCheckLink() {
      const url =
        'https://players.brightcove.net/87105709001/rJEvYlo8_default/index.html?videoId=6129184122001'
      const tab = window.open(url, '_blank')
      tab.focus()
    }
  }
}
</script>
